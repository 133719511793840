.download {
	text-decoration: underline;
	color: #fdfdfd;
	opacity: .7;
	&:hover {
		color: #fdfdfd;
		opacity: 1;
		text-decoration: underline!important;
	}
} 
.text-center {
	text-align: center!important;
}
.prices {
	background: url('../img/to-review.jpg') no-repeat center center!important;
	background-size: cover!important;
	.screen-1 {
		background: url('../img/prices-bg.jpg') no-repeat center center!important;
		background-size: cover!important;
		
		.banner {
			p {
				font-size: 20px;
				line-height: 1.4;
			}
		}
		.banner-footer {
			padding-bottom: 28px;
		}
	}
	.price-table {
		background: #e9e8e8;
		padding: 50px 20px;
		color: #0a0a0a!important;
		.table-bordered td, .table-bordered th {
			padding: 20px;
			line-height: 1.3;
		}
		.bb0 {
			border-bottom: 0;
		}
		.bt0 {
			border-top: 0;
		}
	}
	.about-price {
		margin-top: 50px;
		p {
			line-height: 1.3;
			margin-bottom: 14px;
			font-size: 12px;
		}
	}

}

@media (min-width: 0px) and (max-width:767px) {
	.prices {
		.screen-1 {
			background: url('../img/cons-bg-md.jpg') no-repeat center center;
			background-size: cover!important;

			.banner-footer p{
				color: #d9d7d7!important;
				font-weight: 400;
			}
			.banner {
				p {
					font-size: 15px;
				}
			}
		}

	}
}
@media (min-width: 768px) and (max-width:991px) {
	.text-md-center {
		text-align: center !important;
	}
	.prices {
		.screen-1 {
			background: url('../img/prices-bg-md.jpg') no-repeat center center!important;
			background-size: cover!important;
			
			.banner {
				padding-left: 40px;
				padding-right: 40px;
			}
			.banner-footer {
				padding-left: 40px;
				padding-right: 40px;
			}
			.banner-footer p{
				color: #d9d7d7!important;
				font-weight: 400;
			}
			.cta {
				font-weight: 400;
			}
		}


	}
}