.transportnaya-logistika {
	.screen-1 {
		background: url('../img/tr-log.jpg') no-repeat top center;
		background-size: cover!important;
		.banner-footer {
			padding-bottom: 28px;
		}
	}
	.screen-2 {
		.i1, .i2, .i3, .i4, .i5, .i6, .i7, .i8 {
			position: relative;
			padding: 0 0 0 20px;
			margin-bottom: 50px;
			&:before{
				position: absolute;
				content: '';
			}
		}
		.i1 {
			&:before {
				width: 45px;
				height: 46px;
				background: url('../img/tr-log-i1.png');
				top: 6px;
				left: -42px;
			}
		}
		.i2 {
			&:before {
				width: 38px;
				height: 46px;
				background: url('../img/tr-log-i2.png');
				top: 6px;
				left: -42px;
			}
		}
		.i3 {
			&:before {
				width: 44px;
				height: 46px;
				background: url('../img/tr-log-i3.png');
				top: 6px;
				left: -42px;
			}
		}		
		.i4 {
			&:before {
				width: 45px;
				height: 43px;
				background: url('../img/tr-log-i4.png');
				top: 6px;
				left: -42px;
			}
		}
		.i5 {
			&:before {
				width: 32px;
				height: 46px;
				background: url('../img/tr-log-i5.png');
				top: 6px;
				left: -42px;
			}
		}
		.i6 {
			&:before {
				width: 45px;
				height: 36px;
				background: url('../img/tr-log-i6.png');
				top: 6px;
				left: -42px;
			}
		}
		.i7 {
			&:before {
				width: 52px;
				height: 34px;
				background: url('../img/tr-log-i7.png');
				top: -5px;
				left: -42px;
			}
		}
		.i8 {
			&:before {
				width: 45px;
				height: 42px;
				background: url('../img/tr-log-i8.png');
				top: -5px;
				left: -42px;
			}
		}
	}
	.screen-2-2 {
		margin-top: 80px;
		p {
			color: #d9d7d7;
			font-size: 18px;
			padding: 8px 0;
			line-height: 1.3;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				width: 11px;
				height: 11px;
				border-radius: 50%;
				background: #086608;
				left: -30px;
				top: 14px;
			}
		}
		h2 {
			margin-bottom: 30px;
		}
	}

	.screen-3 {
		.feature {
			display: block;
			width: 270px;
			height: 230px;
			margin: 0 auto 30px auto;
			position: relative;
			.overlay {
				position: absolute;
				background: rgba(0, 0, 0, .9);
				width: 100%;
				height: 111px;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 18px;
				p {
					text-align: center!important;
					font-size: 14px!important;
					color: #d9d7d7!important;
					line-height: 1.5!important;
					left: unset!important;
					padding: 0 !important;
					&:before {
						width: 0;
						height: 0;
					}
				}
			}
		}
		.feature-1 {
			background: url('../img/tran-log-md-f1.jpg') no-repeat center center;
		}
		.feature-2 {
			background: url('../img/tran-log-md-f2.jpg') no-repeat center center;
		}
		.feature-3 {
			background: url('../img/tran-log-md-f3.jpg') no-repeat center center;
		}
		.feature-4 {
			background: url('../img/tran-log-md-f4.jpg') no-repeat center center;
		}

	}

	.screen-4 {
		background: url('../img/tran-log-bg4.jpg') no-repeat top center;
		background-size: cover;
		padding: 150px 0;
	}
	.screen-4-2 {
		.slider {
			margin-top: 0;
		}
	}
}

@media (min-width: 0px) and (max-width:767px) {
	.transportnaya-logistika {
		.screen-1 {
			background: url('../img/tran-log-bg-sm.jpg') no-repeat top center!important;
			background-size: cover!important;
		}
		.screen-2 {
			h2 {
				margin-bottom: 20px;
			}
			.i1, .i2, .i3, .i4, .i5, .i6, .i7, .i8 {
				margin-bottom: 20px !important;
				&:before{
					position: absolute;
					content: '';
					background: url('../img/check-sm.png');
					width: 28px;
					height: 28px;
					top: 0px!important;
					left: -18px!important;
				}
			}
		}
		.screen-4 {
			padding-bottom: 50px!important;
		}
		.screen-4-2 {
			padding: 50px 0!important;
		}
	}
}
@media (min-width: 768px) and (max-width:991px) {
	.transportnaya-logistika {
		.screen-1 {
			background: url('../img/tran-log-bg-md.jpg') no-repeat top center!important;
			background-size: cover!important;
		}
		.screen-2 {
			padding-top: 150px!important;
			.i1, .i2, .i3, .i4, .i5, .i6, .i7, .i8 {
				margin-bottom: 100px !important;
				&:before{
					position: absolute;
					content: '';
					top: -45px!important;
					left: 18px!important;
				}
			}
		}

		.screen-4 {
			background: url('../img/tran-log-bg4-md.jpg') no-repeat top center!important;
			background-size: cover!important;
			padding: 50px;
		}
	}
}