
@font-face {
  font-family: 'voxregular_14';
  src: url('../fonts/10586.woff') format('woff'),
  url('../fonts/10586.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'voxregular_15';
  src:   url('../fonts/10587.woff') format('woff'),
  url('../fonts/10587.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;

}

html {
  // overflow-x: hidden;
}

body {
  font-family: 'Open sans';
  overflow-x: hidden;
  &:before {
    content: 'SM';
    z-index: -100;
    position: fixed;
    display: none;
  }
}

.wow {
  visibility: hidden;
}

//Navi


.r-panel {
  position: fixed;
  width: 50px;
  right: 5px;
  top: 30%;
  cursor: pointer;
  div {
    margin-bottom: 2px;
  }
  p {
    display: none;
  }
  .qi1 {
    background: url('../img/qi1.png') no-repeat center center;
    width: 50px;
    height: 50px;
    &:hover {
      background: url('../img/qi1a.png') no-repeat center center;
    }
  }  .qi2 {
    background: url('../img/qi2.png') no-repeat center center;
    width: 50px;
    height: 50px;
    &:hover {
      background: url('../img/qi2a.png') no-repeat center center;
    }
  }  .qi3 {
    background: url('../img/qi3.png') no-repeat center center;
    width: 50px;
    height: 50px;
    &:hover {
      background: url('../img/qi3a.png') no-repeat center center;
    }
  }  .qi4 {
    background: url('../img/qi4.png') no-repeat center center;
    width: 50px;
    height: 50px;
    &:hover {
      background: url('../img/qi4a.png') no-repeat center center;
    }
  }
}

a:visited {
    color: #fff!important;
}

nav.navbar {
  margin-top: 46px;
}

.dropdown:hover > .dropdown-menu { 
  display: block; 
} 



.dropdown-menu {
  li {
    padding: 8px 20px;
  }
  a, .nav-link {
    display: block;
    width: 100%;
    padding: 0!important;
    clear: both;
    font-weight: 400;
    color: #fff;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 16px!important;
    &:hover {
      color: #086608!important;
    }
    
  }
  li.current a, li.current-menu-item .nav-link {
    color: #086608!important;
    text-decoration: none;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  font-size: 18px;
}
.navbar-brand {
  margin-right: 74px;  
}
.lang {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 36px;
  a {
    color: #fff;
    &:hover {
      text-decoration: none;
      border-bottom: 1px solid green;
    }
  }
  .current {
    border-bottom: 1px solid green;
    font-weight: bold;
  }
}
.search {
  display: inline-block;
  color: #fff;
  font-size: 20px;
  padding: 0 18px 0 0px;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  .search-box {
    position: absolute;
    text-align: center;
    left: -220px;
    bottom: -60px;
    z-index: 99999;
    display: none;
    .close-search-btn {
      position: absolute;
      background: url('../img/close-search.png');
      right: -11px;
      top: -16px;
      width: 12px;
      height: 12px;
      z-index: 999999;
    }
    .search-wrapper{
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      input,.search-btn {
      // display: inline-block;
      height: 40px;
      vertical-align: middle;
      margin: 0;
    }
    input {
      padding-left: 20px;
      box-sizing: border-box;
      width: 200px;
      font-size: 14px;
    }
    .search-btn {
      position: relative;
      color: #086608;
      background: #b7b6b6;
      padding: 14px 10px 0 10px;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      // left: -6px;
    }

  }
}
}

.phone {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  margin-left: 28px;
  span {
    font-weight: lighter;
    font-size: 16px;
  }
}




//Main

.main {
  .screen-1 {
    background: url('../img/to-screen1.jpg') no-repeat top center;
    background-size: cover;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    .banner {
      padding-top: 158px;
      padding-bottom: 160px;
      h1 {
        font-family: 'voxregular_15';
        font-size: 36px;
        color: #fff;
        text-transform: uppercase;
        line-height: 1.4;
      }
      p {
        color: #fff;
        font-size: 22px;
        margin-top: 22px;
      }
    }
    .banner-footer {
      padding-bottom: 40px;
      p {
        color: #d9d7d7;
        font-size: 18px;
      }
      img {
        padding: 0 9px;
      }
    }
  }
  .screen-2 {
    background: url('../img/to-features-bg.jpg') no-repeat top center;
    background-size: cover;
    color: #d9d7d7;
    padding-bottom: 100px;
    padding-top: 80px;
    .feature {
      padding: 0 50px;
      margin-bottom: 0px;
      p {
        font-size: 14px;
        line-height: 1.9;
      }
      img {
        margin-top: 40px;
        margin-bottom: 33px;
      }
    }
  }
  .screen-3 {
    background: url('../img/to-map.jpg') no-repeat top center;
    background-size: cover;
    padding: 150px 0;
    h4 {
      color: #fdfdfd;
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 16px;
      line-height: 1.4;
    }
    p {
      color: #d9d7d7;
      font-size: 18px;
      padding: 8px 0;
      line-height: 1.3;
      position: relative;
      &:before {
        content:'';
        position: absolute;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #086608;
        left: -30px;
        top: 14px;
      }
    }
    h2 {
      margin-bottom: 130px;
    }
    .mtx {
      margin-top: 80px;
    }
  }
  .screen-4, .screen-4-2 {
    background: url('../img/to-review.jpg') no-repeat top center;
    background-size: cover;
    padding: 150px 0;
    h4 {
      font-family: 'voxregular_15';
      font-size: 28px;
      color: #fff;
      text-transform: uppercase;
      line-height: 1.4;
    }
    p, a {
      color: #d9d7d7;
      font-size: 18px;
      padding: 8px 0;
      line-height: 1.3;
      position: relative;
    }
    a {
      text-decoration: underline;
    }
    .px {
      padding: 0 0 0 60px;
    }
  }






  .slider {
    margin-top: 150px;
  }
}

//All

.cta {
  display: inline-block;
  color: #fff;
  background-image: -moz-linear-gradient( 0deg, rgb(8,102,8) 0%, rgb(11,127,11) 100%);
  background-image: -webkit-linear-gradient( 0deg, rgb(8,102,8) 0%, rgb(11,127,11) 100%);
  background-image: -ms-linear-gradient( 0deg, rgb(8,102,8) 0%, rgb(11,127,11) 100%);
  background-image: linear-gradient( 0deg, rgb(8,102,8) 0%, rgb(11,127,11) 100%);
  box-shadow: 0px 5px 6.65px 0.35px rgba(7, 8, 9, 0.2);
  font-size: 18px;
  font-weight: bold;
  padding: 20px 50px;
  margin-top: 80px;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transparent;
    border-radius: 50px;
    transition: all .2s ease;
  }
  &:hover {
    &:after {
      top: -8px;
      left: -8px;
      right: -8px;
      bottom: -8px;
      border: 2px solid rgb(8,102,8);
    }
  }
}

.delay-1s {
 animation-delay: 1s;
}

strong {
  font-weight: bold;
}

h2 {
  font-family: 'voxregular_15';
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.4;
}

.mail-icon-top, {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 20px;
  background: url('../img/icons/mail.png') no-repeat center center;
  &:hover {
    background: url('../img/i-mail-active.png') no-repeat center center;
  }
}

.skype-icon-top {
  display: inline-block;
  width: 26px;
  height: 26px;
  background: url('../img/icons/skype.png') no-repeat center center;
  &:hover {
    background: url('../img/i-skype-active.png') no-repeat center center;
  }
}


.md-vis {
  display: none;
}
.sm-vis {
  display: none;
}

a.ph-n {
  color: #fff;
}

.center-block {
  margin: 0 auto;
}

//Big Footer

.big-footer {
  background: url('../img/to-prefooter.jpg') no-repeat top center;
  background-size: cover;
  padding: 150px 0;
  color: #d9d7d7;
  line-height: 1.4;
  h6 {
    color: #d9d7d7;
    font-size: 20px;
    line-height: 1.4;
    margin-top: 30px;
  }
  .mtx {
    margin-top: 80px;
  }
  p {
    margin-bottom: 20px;
  }
  a {
    color: #d9d7d7;
  }
  input {
    display: block;
    margin: 10px auto;
    width: 80%;
    height: 40px;
  }
  p.location {
    position: relative;
    &:before {
      content:'';
      position: absolute;
      top: 2px;
      left: -43px;
      width: 26px;
      height: 26px;
      background: url('../img/i-location.png');
    }
  }
  p.showmap-1, p.showmap-2, p.recall {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
  } 
  p.phone-prefooter {
    position: relative;
    &:before {
      content:'';
      position: absolute;
      top: 2px;
      left: -43px;
      width: 26px;
      height: 26px;
      background: url('../img/i-phone.png');
    }
  }    
  p.email {
    position: relative;
    display: inline-block;
    &:before {
      content:'';
      position: absolute;
      top: 0px;
      left: -43px;
      width: 26px;
      height: 26px;
      background: url('../img/i-email.png');
    }
  }    
  p.skype {
    position: relative;
    // display: inline-block;
    // margin-left: 60px;
    &:before {
      content:'';
      position: absolute;
      top: 0px;
      left: -43px;
      width: 26px;
      height: 26px;
      background: url('../img/i-skype.png');
    }
  }
}

.container-fluid {
  border-bottom: 0!important;
  border-top: 0!important;
  border-bottom-color: transparent!important;
}

//Footer

.footer {
  background: #070809;
  color: #d9d7d7;
  padding: 50px 0 30px;
  font-size: 14px;
  h1 {
    font-size: 18px;
    margin-bottom: 46px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 57px;
      height: 1px;
      background: #086608;
      bottom: -10px;
      left: 0;
    }
  }
  p {
    line-height: 1.4;
    margin-top: 10px;
  }
  a {
    display: block;
    color: #d9d7d7;
    text-decoration: underline;
    margin-bottom: 11px;
  }
  hr {
    background: #252627;
    margin-top: 60px;
  }
  .footer-icons {
    margin-top: 20px;
    a {
      display: inline-block;
      margin-right: 16px;
      cursor: pointer;
    }
  }
  .copyright {
    padding-top: 16px;
    span {
      font-family: 'voxregular_15';
      font-style: italic;
    }
  }

  .mail-icon-footer, {
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-right: 0px;
    background: url('../img/i-email-footer.png') no-repeat center center;
    &:hover {
      background: url('../img/i-mail-active.png') no-repeat center center;
    }
  }

  .skype-icon-footer {
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url('../img/i-skype-footer.png') no-repeat center center;
    &:hover {
      background: url('../img/i-skype-active.png') no-repeat center center;
    }
  }

}

//Modals

.modal-header {
  border-bottom: 0;
}

.modal-header .close {
  padding-bottom: 0;
}

.modal {
  h1 {
    text-align: center;
    font-size: 24px;
    color: #080808;
    font-weight: bold;
  }
}

.close:active, button:focus {
  outline: none;
}
.close::-moz-focus-inner {
  border: 0;
}

@media (min-width: 0px) and (max-width:767px) {
	.text-xs-left { text-align: left!important; }
	.text-xs-right { text-align: right!important; }
	.text-xs-center { text-align: center!important; }
	.text-xs-justify { text-align: justify!important; }
  .sm-vis{display: block !important;}
  .hidden-sm {display: none !important;}
  .screen-1 {
    background: url('../img/to-screen1-sm.jpg') no-repeat top center!important;
    background-size: cover !important;
  }
  .sm-row {
    margin-top: 20px;
  }
  .phone {
    padding-top: 6px;
  }
  .hrb {
    position: absolute;
    width: 100%;
    height: 1px;
    background: #1b1f21;
    left: 0;
    right: 0;
    top:70px;
  }
  .sm-menu-active {
    padding: 80px 30px 0;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
  }
  .sm-menu-close {
    position: absolute;
    width: 18px;
    height: 16px;
    right: 15px;
    top: 20px;
    background: url('../img/sm-menu-close.png') no-repeat center center;
    z-index: 9999999;
  }
  .main .screen-1 .banner h1 {
    font-size: 18px;
  }
  .main .screen-1 .banner {
    padding-top: 80px;
  }
  .main .screen-1 .banner p {
    font-size: 14px;
  }
  .cta {
    font-size: 14px;
    font-weight: 400;
    padding: 16px 30px;
    margin-top: 80px;
    border-radius: 40px;
  }
  .main .screen-1 .banner-footer {
    padding-bottom: 12px;
  }
  .main .screen-1 .banner-footer p {
    padding-top: 8px;
    font-size: 12px;
  }
  .feature img {
    display: none;
  }
  .screen-2 {
    padding: 50px 0!important;
  }
  .feature p {
    padding: 20px 8px 8px 30px;
    line-height: 1.3!important;
    position: relative;
    &:before {
      content:'';
      width: 28px;
      height: 28px;
      background: url('../img/check-sm.png') no-repeat center center;
      position: absolute;
      left: -11px;
      top: 17px;
    }
    strong {
      font-weight: 400;
    }
  }
  h2 {
    font-size: 18px;
  }
  .screen-3 {
    background: #efecec!important;
    color: #080808!important;
    padding: 50px 20px!important;
    h2, h4, p {
      color: #080808!important;
    }
    h2 {
      margin-bottom: 30px!important;
    }
    h4 {
      font-size: 14px!important;
    }
    p {
      font-size: 14px!important;
      padding: 4px 20px 4px 0!important;
      left: 28px;
    }
    img {
      margin-top: 20px;
    }
    .mtx {
      margin-top: 34px !important;
    }
  }
  .main .screen-4 {
    padding-top: 0!important;
    padding-bottom: 80px;
  }
  .we-sm {
    background: #262728;
    padding: 50px 0px;
    h2 {
      padding: 0 30px 40px;
    }
    .box1, .box-2, .box-3, .box-4 {
      width: 100%;
      background: #d9d7d7;
      p {
        color: #080808;
        font-size: 13px;
        padding: 27px;
      }
      .sert-sm {
        position: absolute;
        width: 100px;
        height: 141px;
        top: -10px;
        left: -10px;
        z-index: 99999;
        display: block !important;
      }
    }
    .box-2 {
      margin-top: 40px;
      background: url('../img/to-bg-map-sm.jpg') no-repeat center center;
      background-size: cover;
    }
    .box-3 {
      margin-top: 40px;
      background: #d9d7d7;
      p {
        padding: 25px;
      }
      .office-sm {
        position: absolute;
        right: -13px;
        top: -7px;
      }
    }
    .box-4 {
      margin-top: 40px;
      background: url('../img/to-bg-is-sm.jpg') no-repeat center center;
      background-size: cover;
    }
  }
  .slider {
    p.sm-vis {
      font-size: 12px;
      line-height: 1.4;
    }
  }
  .big-footer {
    padding: 0;
    background: #262728;
  }
  .sm-headers {
    h3 {
      font-size: 20px;
      color: #fff;
      font-weight: bold;
    }
    p {
      font-size: 14px;
    }
  }
  .big-footer .mtx {
    margin-bottom: 80px;
  }
  .sm-footer {
    h2, p{
      padding: 0 20px;
    }
  }
  .contactTab {
    margin-top: 30px;

    li a {
      text-decoration: none;
      font-weight: bold;
    }
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #d9d7d7!important;
    border-color: #d9d7d7;

  }
  .nav-tabs .nav-link {
    border: 1px solid #232425;
    background: #232425;
    border-top-left-radius: 0;
    border-top-right-radius: 5px;
    margin-bottom: 0;
    margin-bottom: 0;
    padding: 17px 38px;
  }
  .nav-tabs {
    border-bottom: none;
  }
  .tab-content>.active {
    display: block;
    color: #080808;
    background: #d9d7d7;
    margin-top: 1px;
    padding: 30px 0 0;
  }
  .tab-content {
    .p30 {
      padding: 0 30px;
    }
    p, a {
      font-size: 12px!important;
      line-height: 1.5;
      color: #080808;
      text-decoration: none;
      position: relative;
    }
    .loc {
      &:before {
        content:'';
        background: url('../img/i-location-sm.png') no-repeat center center;
        width: 8px;
        height: 10px;
        position: absolute;
        left: 3px;
        top: 3px;
      }
    }    
    .pho {
      &:before {
        content:'';
        background: url('../img/i-phone-sm.png') no-repeat center center;
        width: 8px;
        height: 10px;
        position: absolute;
        left: 3px;
        top: 3px;
      }
    }
    .mai {
      &:before {
        content:'';
        background: url('../img/i-mail-sm.png') no-repeat center center;
        width: 13px;
        height: 10px;
        position: absolute;
        left: 3px;
        top: 3px;
      }
    }    
    .sky {
      &:before {
        content:'';
        background: url('../img/i-skype-sm.png') no-repeat center center;
        width: 13px;
        height: 10px;
        position: absolute;
        left: 3px;
        top: 3px;
      }
    }
    .mai, .sky{
      display: inline-block;
      margin-bottom: 30px;
      a {
        display: inline-block;
      }
    } 
  }
  .footer hr {
    display: none;
  }
  .footer .copyright {
    padding-top: 29px;
    font-size: 12px;
  }
  .up-sm {
    margin-top: 30px;
  }
  .r-panel {
    display: none;
  }
}
@media (min-width: 768px) and (max-width:991px) {
  .text-sm-left { text-align: left!important; }
  .text-sm-right { text-align: right!important; }
  .text-sm-center { text-align: center!important; }
  .text-sm-justify { text-align: justify!important; }
  .r-panel {
    display: none;
  }
  body{
    &:before {
      content: 'MD';
      position: fixed;
      z-index: -100;
    }
  }
  .logo {max-width: 100px;}
  .hidden-md {
    display: none!important;
  }
  .md-vis {
    display: block !important;
  }
  .screen-1 {
    background: url('../img/to-screen1-md.jpg') no-repeat top center!important;
    background-size: cover !important;
  }
  .md-row {
    margin-top: 20px;
    padding: 0 10px;
  }
  nav.navbar {
    margin-top: 10px;
  }
  .order-call-md {
    display: block;
    background-color: rgb(217, 215, 215);
    color: #080808;
    font-size: 12px;
    padding: 10px 40px;
    cursor: pointer;
    border-radius: 18px;
  }
  .main .screen-1 .banner {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .main .screen-2 {
    padding-bottom: 73px;
    padding-top: 50px;
  }
  .main .screen-3 {
    padding: 50px 0;
  }
  .main .screen-3 p {
    font-size: 14px;
  }
  .main .screen-3 h4 {
    font-size: 14px;
  }
  .main .screen-3 p:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 1px;
    background: #ffffff;
    left: -18px;
    top: 16px;
  }
  .main .screen-3 h2 {
    margin-bottom: 59px;
    font-size: 20px;
  }
  .main .screen-4 {
    padding: 50px 0;
  }
  .main .screen-4 a, .main .screen-4 p {
    font-size: 14px;
  }
  .main .screen-4 h4 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  
  h2 {
    font-size: 20px;
  }
  .big-footer {
    padding: 50px 0;
  }
  p.location {
    position: relative;
    &:before {
      content:'';
      position: absolute;
      top: 0px!important;
      left: -26px!important;
      width: 8px;
      height: 10px;
      background: url('../img/i-location-md.png') no-repeat center center!important;
    }
  }

  p.phone-prefooter {
    position: relative;
    &:before {
      content:'';
      position: absolute;
      top: 0px!important;
      left: -26px!important;
      width: 7px;
      height: 10px;
      background: url('../img/i-phone-md.png')no-repeat center center!important;
    }
  }    
  p.email {
    position: relative;
    &:before {
      content:'';
      position: absolute;
      top: 0px!important;
      left: -26px!important;
      width: 13px;
      height: 10px;
      background: url('../img/i-email-md.png')no-repeat center center!important;
    }
  }    
  p.skype {
    position: relative;
    &:before {
      content:'';
      position: absolute;
      top: -4px!important;
      left: -26px!important;
      width: 13px;
      height: 13px;
      background: url('../img/i-skype-md.png')no-repeat center center!important;
    }
  }
  .big-footer p {
    margin-bottom: 10px;
  }
  .big-footer {
    .cta {
      padding: 11px 50px;
      font-weight: 400;
    }
  }
  .slider {
    h2,p {
      text-align: left!important;
    }
  }
}

@media (min-width: 992px) and (max-width:1199px) {
  .text-md-left { text-align: left!important; }
  .text-md-right { text-align: right!important; }
  .text-md-center { text-align: center!important; }
  .text-md-justify { text-align: justify!important; }
  body{
    &:before {
      content: 'LG';
      position: fixed;
      z-index: -100;
    }
  }
}

@media (min-width: 1200px) {
  .text-lg-left { text-align: left!important; }
  .text-lg-right { text-align: right!important; }
  .text-lg-center { text-align: center!important; }
  .text-lg-justify { text-align: justify!important; }
  body{
    &:before {
      content: 'XL';
      position: fixed;
      z-index: -100;
    }
  }
}