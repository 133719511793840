.sertifikatsiya {
	background: url('../img/to-review.jpg') no-repeat center center!important;
	background-size: cover!important;
	.screen-1 {
		background: transparent;
		.banner {
			p {
				font-size: 20px;
				line-height: 1.4;
			}
		}
		.banner-footer {
			padding-bottom: 28px;
		}
	}
	.screen-2 {
		padding-bottom: 0;
		.innerbg {
			background: #1a1b1c;
			box-shadow: 0px 2px 14px 6px rgba(6, 6, 6, 0.15);
			padding: 20px 0 0;
		}
		background: transparent;
		.feature {
			padding: 0 20px;
		}
		h5 {
			font-weight: bold;
			font-size: 18px;
			margin-bottom: 20px;
		}

	}
	.screen-2-2 {
		margin-top: 80px;
		p {
			color: #d9d7d7;
			font-size: 18px;
			padding: 8px 0;
			line-height: 1.3;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				width: 11px;
				height: 11px;
				border-radius: 50%;
				background: #086608;
				left: -30px;
				top: 14px;
			}
		}
		h2 {
			margin-bottom: 30px;
		}
	}

	.screen-3 {
		.feature {
			display: block;
			width: 270px;
			height: 230px;
			margin: 0 auto 30px auto;
			position: relative;
			.overlay {
				position: absolute;
				background: rgba(0, 0, 0, .9);
				width: 100%;
				height: 111px;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 18px;
				p {
					text-align: center!important;
					font-size: 14px!important;
					color: #d9d7d7!important;
					line-height: 1.5!important;
					left: unset!important;
					padding: 0 !important;
					&:before {
						width: 0;
						height: 0;
					}
				}
			}
		}
		.feature-1 {
			background: url('../img/sklad-log-f1-md.jpg') no-repeat center center;
		}
		.feature-2 {
			background: url('../img/sklad-log-f2-md.jpg') no-repeat center center;
		}
		.feature-3 {
			background: url('../img/sklad-log-f3-md.jpg') no-repeat center center;
		}
		.feature-4 {
			background: url('../img/sklad-log-f4-md.jpg') no-repeat center center;
		}
		.feature-5 {
			background: url('../img/sklad-log-f5-md.jpg') no-repeat center center;
		}

	}

	.screen-4 {
		background: url('../img/tran-log-bg4.jpg') no-repeat top center;
		background-size: cover;
		padding: 0;
	}
	.screen-4-2 {
		background: transparent;
		.slider {
			margin-top: 0;
		}
	}
}

@media (min-width: 0px) and (max-width:767px) {
	.sertifikatsiya {
		.screen-1 {
			background: url('../img/sert-bg-sm.jpg') no-repeat top center!important;
			background-size: cover!important;
			h1, p{
				color: #080808!important;
			}
			p {
				font-weight: 400;
			}
			.banner-footer p{
				color: #d9d7d7!important;
				font-weight: 400;
			}
			.banner {
				p {
					font-size: 15px;
				}
			}
		}
		.screen-2 {
			.innerbg{
				background: transparent!important;
				box-shadow: unset;
				padding: 0;
			}
			h2 {
				margin-bottom: 20px;
			}
			h5 {
				padding: 20px 8px 0px 40px;
				font-size: 14px;
				top: 5px;
				margin-bottom: 4px;
			}
			p {
				font-size: 14px;
				padding: 0px 8px 20px 40px;
				&:before {
					left: 4px;
					top: 5px;
				}
			}
			.feature {
				background: #1a1b1c;
				box-shadow: 0 2px 14px 6px rgba(6,6,6,.15);
				margin: 0px 0px 20px;
				padding: 20px;
				img {
					display: block;
					margin: 0 auto 20px auto;
				}
				p {
					text-align: center;
					padding: 0;
					margin-bottom: 30px;
					&:before {
						display: none;
					}
				}
			}
			.screen-2-2 {
				margin-top: 0;
				margin-bottom: 60px;
			}
		}
		.screen-3 {
			padding-bottom: 0!important; 
			background: #1a1b1c!important;
			h2 {
				color: #d9d7d7!important;
			}
		}
		.screen-4 {
			padding-bottom: 0px!important;
			background: unset;
		}
		.screen-4-2 {
			padding: 50px 0!important;
		}
	}
}
@media (min-width: 768px) and (max-width:991px) {
	.text-md-center {
		text-align: center !important;
	}
	.sertifikatsiya {
		.screen-1 {
			background: url('../img/sert-bg-md.jpg') no-repeat bottom center!important;
			background-size: cover!important;
			h1, p{
				color: #080808!important;
			}
			p {
				font-weight: bold;
			}
			.banner-footer p{
				color: #d9d7d7!important;
				font-weight: 400;
			}
		}
		.screen-2 {
			padding-top: 20px!important;
			.innerbg {
				background: transparent;
			}

		}
		.screen-2-2 {
			background: #1a1b1c;
			box-shadow: 0px 2px 14px 6px rgba(6, 6, 6, 0.15);
			padding-top: 40px;
			padding-bottom: 40px;
			margin-left: 20px;
			margin-right: 20px;
			img.mtx {
				margin-top: 30px;
			}
		}

		.screen-4 {
			background: url('../img/tran-log-bg4-md.jpg') no-repeat center center!important;
			background-size: cover!important;
			padding: 0px;
		}
		.screen-4-2 {
			padding-top: 70px;
		}
	}
}