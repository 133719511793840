
.contacts {
	background: #0c0c0d;
	.screen-1 {
		padding-bottom: 40px;
		background: #0c0c0d;
	}
	.screen-2 {
		background: url('../img/contacts-bg.jpg') no-repeat center center!important;
		background-size: cover;
		h5 {
			line-height: 1.4;
			margin-top: 10px;
			margin-bottom: 50px;
		}
		h3 {
			font-size: 30px;
			font-weight: 500;
			color: #fdfdfd;
			margin-bottom: 30px;
			position: relative;
			left: -40px;
		}
		.desktop-contacts {
			padding: 0 !important;
			margin-bottom: 40px;
			background: transparent!important;
		}
		.screen-2-2 {
			margin-top: 40px;
			h3 {
				left: unset;
				margin-bottom: 10px;
				font-weight: bold;
			}
			h5 {
				margin-top: 10px;
			}
		}
	}
}

@media (min-width: 0px) and (max-width:767px) {
	.contacts {
			h2 {
				font-size: 14px;
			}
			h5 {
				font-size: 12px;
				margin-bottom: 0!important;
			}

	}
}
@media (min-width: 768px) and (max-width:991px) {

	.contacts {



	}
}