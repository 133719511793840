.skladskaya-logistika {
	.screen-1 {
		background: url('../img/sklad-log-bg.jpg') no-repeat top center;
		background-size: cover!important;
		.banner {
			p {
				font-size: 20px;
				line-height: 1.4;
			}
		}
		.banner-footer {
			padding-bottom: 28px;
		}
	}
	.screen-2 {
		.feature {
			padding: 0 20px;
		}
		h5 {
			font-weight: bold;
			font-size: 18px;
			margin-bottom: 20px;
		}

	}
	.screen-2-2 {
		margin-top: 80px;
		p {
			color: #d9d7d7;
			font-size: 18px;
			padding: 8px 0;
			line-height: 1.3;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				width: 11px;
				height: 11px;
				border-radius: 50%;
				background: #086608;
				left: -30px;
				top: 14px;
			}
		}
		h2 {
			margin-bottom: 30px;
		}
	}

	.screen-3 {
		.feature {
			display: block;
			width: 270px;
			height: 230px;
			margin: 0 auto 30px auto;
			position: relative;
			.overlay {
				position: absolute;
				background: rgba(0, 0, 0, .9);
				width: 100%;
				height: 111px;
				bottom: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 18px;
				p {
					text-align: center!important;
					font-size: 14px!important;
					color: #d9d7d7!important;
					line-height: 1.5!important;
					left: unset!important;
					padding: 0 !important;
					&:before {
						width: 0;
						height: 0;
					}
				}
			}
		}
		.feature-1 {
			background: url('../img/sklad-log-f1-md.jpg') no-repeat center center;
		}
		.feature-2 {
			background: url('../img/sklad-log-f2-md.jpg') no-repeat center center;
		}
		.feature-3 {
			background: url('../img/sklad-log-f3-md.jpg') no-repeat center center;
		}
		.feature-4 {
			background: url('../img/sklad-log-f4-md.jpg') no-repeat center center;
		}
		.feature-5 {
			background: url('../img/sklad-log-f5-md.jpg') no-repeat center center;
		}

	}

	.screen-4 {
		background: url('../img/tran-log-bg4.jpg') no-repeat top center;
		background-size: cover;
		padding: 150px 0;
	}
	.screen-4-2 {
		.slider {
			margin-top: 0;
		}
	}
}

@media (min-width: 0px) and (max-width:767px) {
	.skladskaya-logistika {
		.screen-1 {
			background: url('../img/sklad-log-bg-sm.jpg') no-repeat top center!important;
			background-size: cover!important;
			.banner {
				p {
					font-size: 15px;
				}
			}
		}
		.screen-2 {
			h2 {
				margin-bottom: 20px;
			}
			h5 {
				padding: 20px 8px 0px 40px;
				font-size: 14px;
				top: 5px;
				margin-bottom: 4px;
			}
			p {
				padding: 0px 8px 0px 40px;
				&:before {
					left: 4px;
					top: -19px;
				}
			}
			.i1, .i2, .i3, .i4, .i5, .i6, .i7, .i8 {
				margin-bottom: 20px !important;
				&:before{
					position: absolute;
					content: '';
					background: url('../img/check-sm.png');
					width: 28px;
					height: 28px;
					top: 0px!important;
					left: -18px!important;
				}
			}
		}
		.screen-3 {
			padding-bottom: 0!important; 
			background: #1a1b1c!important;
			h2 {
				color: #d9d7d7!important;
			}
		}
		.screen-4 {
			padding-bottom: 0px!important;
			background: unset;
		}
		.screen-4-2 {
			padding: 50px 0!important;
		}
	}
}
@media (min-width: 768px) and (max-width:991px) {
	.skladskaya-logistika {
		.screen-1 {
			background: url('../img/sklad-log-bg-md.jpg') no-repeat top center!important;
			background-size: cover!important;
		}
		.screen-2 {
			padding-top: 20px!important;
			.i1, .i2, .i3, .i4, .i5, .i6, .i7, .i8 {
				margin-bottom: 100px !important;
				&:before{
					position: absolute;
					content: '';
					top: -45px!important;
					left: 18px!important;
				}
			}
		}

		.screen-4 {
			background: url('../img/tran-log-bg4-md.jpg') no-repeat top center!important;
			background-size: cover!important;
			padding: 50px;
		}
	}
}